import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import PayHome from './Customer/PayHome';
import Footer from './Components/Footer';
import Whatsapp from './Components/Whatsapp';



function App() {
  return (
    <div className="App">
     <PayHome />  
    
     {/* <Footer /> */}
       </div>
  );
}

export default App;
