import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Background from '../assets/back.jpg';
import Logo from '../assets/satellite.jpg';
import Text from 'react-text';
import { Input } from '@mui/material';
import Popper from '@mui/material/Popper';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';



function Copyright(props) {

  return (
    <></>
    // <Typography variant="body2" color="text.secondary" align="center" {...props}>
    //   {'Copyright © '}
    //   <Link color="inherit" href="https://mui.com/">
    //     Cable Man
    //   </Link>{' '}
    //   {new Date().getFullYear()}
    //   {'.'}
    // </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function PayHome() {

  const [custsearch, custsearchchange] = useState(null);
  const [custName, custNamechange] = useState("");
  const [amount, amountchange] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [order, setOrder] = useState(null);
  const [buttonupdate, setButtonupdate] = useState(false);
  const [paymentid, paymentidchange] = useState(null);
  const toast = useRef(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const showSuccess = (id) => {
    toast.current.show({ severity: 'success', summary: 'Success', detail: 'Your Payment ID is ' + id, life: 3000 });
  }


  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const fetchbtn = (e) => {
    e.preventDefault();
    const fetchData = async () => {
      try {
        const response = await fetch("https://api.cableman.app/TECH/techniques/cust_payment_search/" + custName);

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const result = await response.json();
        setData(result.data);
        amountchange(result.data.amount);
        setStatus(result.success);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

  }

  const createRazorpayOrder = async () => {
    try {
      // Make a request to your server to create a Razorpay order
      const response = await fetch('https://api.cableman.app/TECH/techniques/company/2/payment_order', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        // Include any data needed to create the order on the server
        body: JSON.stringify({
          amount: amount + '00', // Replace with the actual amount
          currency: 'INR',
          crfNo: custName, // Replace with the actual currency code
          // Add other required parameters
        }),
      });

      const orderData = await response.text();
      setOrder(orderData);
      console.log(orderData);
      setButtonupdate(true);

    } catch (error) {
      console.error('Error creating Razorpay order:', error);
    }
  };

  const handlePayment = () => {
    if (!order) {
      console.error('Order not created');
      return;
    }

    // Initialize Razorpay with your key and order details
    const razorpay = new window.Razorpay({
      key: 'rzp_test_xWHdJ0PNIrG5Rr', // Replace with your actual Razorpay key
      amount: amount + '00',
      currency: 'INR',
      order_id: order,
      name: 'Techniques',
      description: 'Bill Payments',
      handler: function (response) {
        // Handle the success callback
        console.log('Payment success:', response.razorpay_payment_id);
        setButtonupdate(false);
        setStatus(false);
        showSuccess(response.razorpay_payment_id);
        // After successful payment, you can perform additional actions here
        // For example, mark the order as completed on the server

      },
    });

    // Open the Razorpay checkout form
    razorpay.open();
  };


  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <Toast ref={toast} />
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: "url(" + Background + ")",
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div>
              <img src={Logo} height={80} />
            </div>
            <Typography component="h1" variant="h5">
              QUICK PAY
            </Typography>
            <Box component="form" noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="customerid"
                label="Customer ID"
                name="customerid"
                autoFocus
                onChange={(e) => custNamechange(e.target.value)}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={fetchbtn}
              >
                SEARCH
              </Button>
              {status &&
                <Text>


                  <Typography align='left' sx={{ fontWeight: 600 }}>
                    Name : {data.name}
                  </Typography>
                  <Typography align='left' sx={{ fontWeight: 600 }}>
                    Address : {data.address}
                  </Typography>
                  <Typography align='left' sx={{ fontWeight: 600 }}>
                    Billed Amount : {data.amount}
                  </Typography>
                  <Typography align='left' sx={{ fontWeight: 600 }}>
                    Amount : <Input onChange={(e) => amountchange(e.target.value)} />
                  </Typography>
                  <div className="App">
                    {!buttonupdate &&
                      <Button
                        onClick={createRazorpayOrder}
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}

                      >
                        PAY
                      </Button>
                    }

                    {buttonupdate &&
                      <Button
                        onClick={handlePayment}
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}

                      >
                        CONFIRM PAYMENT
                      </Button>
                    }

                  </div>

                </Text>

              }
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <div>
        <Popper id={id} open={open} anchorEl={anchorEl}>
          <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}>
            <h1>Sample</h1>
          </Box>
        </Popper>
      </div>

    </ThemeProvider>


  );
}

